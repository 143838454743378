import { Auth } from 'aws-amplify';
import Amplify from '@aws-amplify/core';
import React, { useEffect, useMemo } from 'react';
import { Fragment } from 'react';
import {
  publicApiMapping,
  usePublicQueryStates,
} from '../../hooks/useQueryCustomHooks/usePublicQueryStates';
import { eMessageType } from '../../types/IMessageType';
import getAwsconfig from '../../aws-exports';
import { useGetUserInfoAndAuthorities } from '../../hooks/useQueryCustomHooks/useGetUserInfoAndAuthorities';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import apiToUrlMap from '../../ApiMapping';
import { useQueryClientService } from '../../hooks/useQueryCustomHooks/useDataService';
import {
  setCurrencyValuesToLocalStorage,
  setNumberFormatterToLocalStorage,
} from '../../_lib/formatting';
import { isEmpty } from '../../_lib/util';

export const getConfigFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('PxConfig') || '{}');
};
const setConfigToLocalStorage = async (config: any) => {
  localStorage.setItem('PxConfig', JSON.stringify(config));
};

const setConfigAndStyle = (config: any) => {
  const settings = config?.dark_mode_features;
  const style = document.createElement('style');
  style.innerHTML = settings;
  document.documentElement.appendChild(style);
  Amplify.configure(getAwsconfig(config));
};

const setCurrentUserAuthority = async (fetchUserInfo: any, queryClient: QueryClient) => {
  try {
    await Auth.currentAuthenticatedUser();
    fetchUserInfo();
  } catch (error) {
    queryClient.setQueryData([apiToUrlMap.getUserInfo], (prev: any) => ({
      ...prev,
      isSignedIn: false,
    }));
  }
};

const setConfigAnfStyleInRedux = async (config: any, setQueryCacheData: any) => {
  try {
    setConfigAndStyle(config);
  } catch (error: any) {
    console.log(error);
    setQueryCacheData(apiToUrlMap.getUserInfo, null, (prev: any) => ({
      ...prev,
      isSignedIn: false,
    }));
  }
};

export const preProcessConfig = async (remoteConfig: any) => {
  // // fetch in background and update local storage
  // const remoteConfig = await fetchConfig(process.env.REACT_APP_STAGE);
  // // if the tenant is deactivated - config will have key deactivated
  if (remoteConfig.deactivated) {
    setConfigToLocalStorage(remoteConfig);
    return { ...remoteConfig, deactivated: true };
  }

  let processedConfig = {};
  const parsedConfig = await getConfigFromLocalStorage();
  // always set remote config, but only when it doesn't exist, or versions do not match local
  if (!parsedConfig.appVersion || parsedConfig.appVersion !== remoteConfig.appVersion) {
    setConfigToLocalStorage(remoteConfig);
    let isSignedIn = false;
    if (parsedConfig.appVersion) {
      try {
        await Auth.currentAuthenticatedUser();
        isSignedIn = true;
      } catch (error) {}
    }

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    if (parsedConfig.appVersion && isSignedIn) {
      processedConfig = {
        ...remoteConfig,
        CLIENT_FLAG_STATE: {
          message: (
            <>
              A Newer App Version is Available{' '}
              <a
                onClick={function () {
                  window.location.reload();
                }}
                href="##"
                className="px-underline px-color-text-on-snackbar"
              >
                REFRESH APP
              </a>
            </>
          ),
          type: 'SNACKBAR',
          severity: eMessageType.success,
          open: true,
        },
      };
    }
    ['userInfo', 'PxSideMenuItems', 'PxUserAuthorities', 'PxI18n'].forEach((key) =>
      localStorage.removeItem(key)
    );
    setCurrencyValuesToLocalStorage(remoteConfig);
    setNumberFormatterToLocalStorage(remoteConfig);
  }
  return !isEmpty(processedConfig) ? processedConfig : remoteConfig;
};

export const ConfigWrapper = (props: any) => {
  const { fetchUserInfo } = useGetUserInfoAndAuthorities();
  const queryClient = useQueryClient();
  const { setQueryCacheData } = useQueryClientService();

  const initialData = useMemo(() => {
    const config = getConfigFromLocalStorage();
    if (!Object.keys(config).length) return;
    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: configData } = usePublicQueryStates<any>({
    url: publicApiMapping.config,
    initialData,
    initialDataUpdatedAt: 0,
    staleTime: Infinity,
    preprocessFetchedData: async (res: any) => {
      let data = await res.json();
      if (data && data.status === 200) data = data.results;
      else data = {};
      return await preProcessConfig(data);
    },
  });

  useEffect(() => {
    if (!configData) return;
    console.log('setting config', configData);
    setConfigAnfStyleInRedux(configData, setQueryCacheData);
    setCurrentUserAuthority(fetchUserInfo, queryClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  return <Fragment>{props.children}</Fragment>;
};
