import { combineReducers } from 'redux';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  userState: userReducer,
});

export const InitialState = () => {
  const initState = {
    userState: null,
  };
  return initState;
};

export default rootReducer;
