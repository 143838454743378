export const defaultParticipant: string = 'pxStocklistId';
export const publicPrivateRoute = {
  accountDetails: '/account-details',
  freightForwarder: '/forms/freight-forwarding-form',
  freightForwarderv2: '/forms/freight-forwarding-form/v2',
  eus: '/forms/eus',
  eusv1: '/forms/eus/v1',
  eusv2: '/forms/eus/v2',
  addendum: '/forms/eus-addendum/v1',
  shippingConsentForm: '/forms/shipping-consent-form',
  masterAgreementForm: '/forms/master-purchase-agreement',
  complianceQuestionnaire: '/forms/compliance-questionnaire',
};

export const accountsetupPrivateRoute = {
  accountSetup: '/account-setup',
};

export const rmaRoutesMap = {
  rmaRequests: '/rma-requests',
  rmaRequestDetails: '/rma-requests/{rmaRequestId}',
};
