import { dataFormatting, searchEngine } from '../../_lib/lib';
import { isEmpty } from '../../_lib/util';
import { IFilterEngineParams, IFilterEngineRes, TabRuleFn } from '../../types/Filters';

interface ISearchEngineV1 {
  // searchOption: string;
  searchTerm: string;
  pieces?: Array<string>;
  item?: any;
  view: string;
  settings: any;
}

interface IConstructLabel {
  fieldName: any;
  settings: any;
  id?: any;
}

const getFilterCount = (currentValue: number | undefined, shouldIncrement: boolean) => {
  return (currentValue || 0) + (shouldIncrement ? 1 : 0);
};

const computeTabCounts = (
  currentTab: string,
  tabCounts: Record<string, number>,
  item: Parameters<TabRuleFn>[0],
  tab: any,
  isSelectedTabMatch: boolean,
  settings: IFilterEngineParams['settings']
) => {
  Object.keys(tab!.rules).forEach((ruleKey) => {
    let isTabMatch = true;
    if (ruleKey === currentTab) isTabMatch = isSelectedTabMatch;
    else isTabMatch = tab!.rules[ruleKey](item, settings);
    tabCounts[ruleKey] = getFilterCount(tabCounts[ruleKey], isTabMatch);
  });
};

const preProcessFiltersApplied = (
  filtersApplied: IFilterEngineParams['filtersApplied'],
  filterSequence: IFilterEngineParams['filterSequence']
) => {
  return Object.keys(filterSequence).reduce((acc, filter) => {
    if (filter in filtersApplied) {
      return { ...acc, [filter]: filtersApplied[filter] };
    }
    return acc;
  }, {});
};

const getInitialFilters = (
  defaultValues: Record<string, any> | undefined,
  filtersApplied: IFilterEngineParams['filtersApplied'],
  filterSequence: IFilterEngineParams['filterSequence']
) => {
  let appliedFilters: Record<string, any> = {};
  if (!isEmpty(filtersApplied)) appliedFilters = { ...filtersApplied };
  if (defaultValues) {
    for (let defaultFilterOption in defaultValues) {
      if (!(defaultFilterOption in appliedFilters))
        appliedFilters[defaultFilterOption] = defaultValues[defaultFilterOption];
    }
  }
  return preProcessFiltersApplied(appliedFilters, filterSequence);
};

export const filterEngine = ({
  filtersList,
  filtersApplied,
  filterSequence,
  filterRules,
  searchState,
  viewName,
  items,
  settings,
  tabState,
  tab,
  defaultValues,
  getAttributeCounts,
  computeFilteredItemsWithoutTab,
  config,
}: IFilterEngineParams): Promise<IFilterEngineRes> => {
  return new Promise((res) => {
    const results: any = {
      itemsState: [],
      tabCounts: {},
    };

    let appliedFilters: IFilterEngineParams['filtersApplied'];

    if (!config.hideFilters) {
      results.filterCounts = {};
      appliedFilters = getInitialFilters(defaultValues, filtersApplied, filterSequence);
    }

    items.forEach((item) => {
      let isTabMatch = true,
        isSearchTermMatch = true,
        isOtherFilterMatch = true,
        filterInfo: Record<string, boolean> = {};

      let transformedItem: any = { ...item };

      //search term match
      if (searchState) {
        // const pieces = getSearchPieces({ searchState, view: viewName });
        if(config.isGeneralSearch) isSearchTermMatch = searchState ? searchEngine(searchState, item, {}) : true;
        else
        isSearchTermMatch = searchEngineV1({
          searchTerm: searchState.trim().toLowerCase(),
          settings: {},
          view: viewName,
          item: transformedItem,
        });
      }

      if (isSearchTermMatch) {
        //tab match
        if (tab && tabState) isTabMatch = tab.rules[tabState](transformedItem, settings);

        //other filters Match
        if (!config.hideFilters) {
          isOtherFilterMatch = Object.keys(appliedFilters).every((filter) => {
            //filters with custom filter functions
            if (filterRules && filter in filterRules) {
              const filterPassed = filterRules[filter](transformedItem, appliedFilters);
              filterInfo[filter] = filterPassed;
              return filterPassed;
            }
            //filters without custom filter functions
            const filterValue = appliedFilters[filter];
            if (typeof filterValue !== 'boolean') {
              if (filterValue[transformedItem[filter] || '(Blanks)']) {
                filterInfo[filter] = true;
                return true;
              }
            }
            filterInfo[filter] = false;
            return false;
          });
        }
      }
      const itemSurvivesAllFilters = isSearchTermMatch && isOtherFilterMatch;
      const absoluteCountIncrement = isSearchTermMatch;

      if (itemSurvivesAllFilters && isTabMatch) {
        results.itemsState.push({ ...transformedItem });
      }

      if (computeFilteredItemsWithoutTab && itemSurvivesAllFilters) {
        results.filteredItemsWithoutTab?.push({ ...transformedItem });
      }

      //tab counts
      if (tab && tabState && itemSurvivesAllFilters) {
        computeTabCounts(tabState, results.tabCounts, transformedItem, tab, isTabMatch, settings);
      }

      //compute counts
      if (!config.hideFilters) {
        if (isEmpty(filtersApplied)) {
          //if filters applied is empty - compute only the absolute count for all filters -  the absolute count is calculated after pre-filtering through switch filters and search filters
          filtersList.forEach((filter) => {
            let attributeFilterValue = results.filterCounts[filter];
            if (typeof attributeFilterValue === 'number') return;
            let filteredValueForItem;
            if (getAttributeCounts && filter in getAttributeCounts) {
              filteredValueForItem = getAttributeCounts[filter](transformedItem, settings);
            } else filteredValueForItem = [transformedItem[filter] || '(Blanks)'];
            if (!attributeFilterValue) attributeFilterValue = {};

            filteredValueForItem.forEach((filteredValue: any) => {
              if (typeof attributeFilterValue === 'number') return;
              attributeFilterValue[filteredValue] = getFilterCount(
                attributeFilterValue[filteredValue],
                absoluteCountIncrement
              );
            });
            results.filterCounts[filter] = attributeFilterValue;
          });
        } else {
          const temp: Record<string, number | Record<string, number>> = {};

          filtersList.forEach((filter) => {
            let attributeFilterValue = results.filterCounts[filter];
            let tempFilterValue = temp[filter];
            if (
              !tempFilterValue &&
              typeof tempFilterValue !== 'number' &&
              typeof attributeFilterValue !== 'number'
            ) {
              tempFilterValue = {};
              if (!attributeFilterValue) attributeFilterValue = {};
              let filteredValueForItem: any;
              if (getAttributeCounts && filter in getAttributeCounts) {
                filteredValueForItem = getAttributeCounts[filter](transformedItem, settings);
              } else filteredValueForItem = [transformedItem[filter] || '(Blanks)'];

              filteredValueForItem.forEach((filterValue: any) => {
                if (typeof attributeFilterValue === 'number' || typeof tempFilterValue === 'number')
                  return;
                const filterCount = getFilterCount(
                  attributeFilterValue[filterValue],
                  itemSurvivesAllFilters
                );
                attributeFilterValue[filterValue] = filterCount;
                tempFilterValue[filterValue] = filterCount;
              });

              temp[filter] = tempFilterValue;
              results.filterCounts[filter] = attributeFilterValue;
            }
          });
        }
      }
    });

    res(results);
  });
};

export const specialFilters: any = {
  AuctionBidding: {
    lotName: 'string',
    grade: 'string',
  },
  AuctionList: {
    auctionDescription: 'string',
    auctionNumber: 'string',
  },
  AccountBalance:{
    multipleSearch: true,
    multipleSearchCol: 'documentNumber',
    multipleSearchRegex: /[\s,]/,
    documentDate: 'date',
    documentType: "string",
    documentNumber: /[\s,]/,
    documentGroup: "string",
    // documentAmount: "number",
    // amountOwed:"number",
    formattedDocumentAmount: "currency",
    formattedAmountOwed: "currency",
    cloneDocumentNumber: "string"
  }
};

const removeTwoSimultaneousSpaces = (data: string): string => {
  let newStr = '';
  for (let index = 0; index < data.length; index++) {
    if (data[index] === ' ' && data[index + 1] === ' ') {
      index++;
    }
    newStr += data[index];
  }

  return newStr;
};

const generateLabelKey = (fieldName: string) => `${fieldName}Labels`;

export const constructLabel = ({ fieldName, settings, id = '' }: IConstructLabel) => {
  const label = generateLabelKey(fieldName);
  const labelValid = settings[label] && settings[label][id];
  let returnLabel = labelValid ? settings[label][id] : fieldName;
  if (fieldName === 'buyerId') {
    returnLabel = returnLabel.buyerName;
  }
  return returnLabel;
};

interface IGenerateConstructedLabel {
  specialFilterName: any;
  settings: any;
  item: any;
}

const generateConstructedLabel = ({
  specialFilterName,
  item,
  settings,
}: IGenerateConstructedLabel) => {
  switch (specialFilterName) {
    case 'stocklistDescription':
      return '';
    //   return stocklistRowGroupDescriptionGetter(settings)({
    //     data: item,
    //   });
    case 'stocklistRowDescription':
      //   return Object.values(
      //     stocklistRowDescriptionGetter({
      //       settings,
      //       item,
      //     })
      //   ).join(' ');
      return '';
    case 'defaultChannel':
      //   return defaultChannelValueDisplayMap[item.channel];
      return '';
    default:
      return '';
  }
};

const searchEngineV1 = ({
  searchTerm,
  pieces,
  item,
  view,
  settings,
}: ISearchEngineV1): boolean => {
  let res: boolean = false;
  Object.keys(specialFilters[view]).forEach((val) => {
    if (
      res ||
      (!item[val] && specialFilters[view][val] !== 'generated') ||
      val.includes('multiple')
    ) {
      return;
    }
    try {
      // only support single nested items => must be an array
      if (val.includes('.')) {
      }
      let searchStr = '';
      // look for types
      switch (specialFilters[view][val]) {
        case 'string':
          searchStr =
            val === 'description' || val === 'skuDescription'
              ? removeTwoSimultaneousSpaces(item[val])
              : `${item[val]}`;
          break;
        case 'label':
          searchStr = constructLabel({
            fieldName: val,
            id: item[val],
            settings: settings,
          });
          break;
        case 'currency':
          searchStr = dataFormatting('currency', item[val]);
          break;
        case 'generated':
          searchStr = generateConstructedLabel({ specialFilterName: val, item, settings });
          break;
        case 'number':
          break;
        case 'array':
          searchStr = item[val].map((itemVal: string) => {
            return itemVal.toLowerCase();
          });
          break;
        case 'exactString':
          searchStr = item[val];
          break;
        default:
          break;
      }

      switch (specialFilters[view][val]) {
        case 'array':
          res = searchStr.includes(searchTerm);
          break;
        case 'exactString':
          res = searchStr === searchTerm;
          break;
        default:
          res = searchStr.toLowerCase().trim().includes(searchTerm);
          break;
      }
    } catch (error: any) {
      // console.log('unable to search through: ', val, 'error:', error.message);
    }
  });
  // }
  return res;
};
