import React from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isFuture from 'date-fns/isFuture';
import {
  IBillingShippingOptions,
  ILineItem,
  IOrder,
  IPaymentMethodPayload,
  OrderOptions,
  OrderUpsertError,
  ShippingAddressIdRealTimeShippingMethodMapping,
} from '../../types/IOrderTypes';
import { checkNullOrUndefined, dataFormatting } from '../../_lib/lib';
import { billMyAccountOrder, isAllViaPhonexAndHasExternalSystem } from '../../_lib/rules';
import { deepCopy } from '../../_lib/util';

const isShippingMethodOverLimit = (options: IBillingShippingOptions, devicesInShipment: number) => (
  shippingMethod: any
) => {
  // get payload from order options
  let shippingOptionPayload: any = options.otherShippingMethods[shippingMethod];
  if (billMyAccountOrder(shippingMethod)) {
    shippingOptionPayload = options.billMyAccountShippingMethod;
  }
  // apply rule if maxDevicesInShipment present in the options
  if (
    !checkNullOrUndefined(shippingOptionPayload?.maxDevicesInShipment) &&
    devicesInShipment > shippingOptionPayload.maxDevicesInShipment
  ) {
    return shippingMethod;
  }
  return false;
};

export const getOverLimitPaymentMethods = (
  paymentMethodOptions: IBillingShippingOptions,
  salesOrder: IOrder
) => {
  const subTotal = getOrderSubTotal(salesOrder);
  const overLimitPaymentMethods: Array<string> = [];
  paymentMethodOptions.paymentMethods.map((paymentMethod) => {
    if (isPaymentMethodOverLimit(subTotal, paymentMethod)) {
      overLimitPaymentMethods.push(paymentMethod.code);
    }
    return paymentMethod;
  });
  return overLimitPaymentMethods;
};

export const hideInvalidPaymentMethodOptions = (
  shippingOptions: IBillingShippingOptions,
  invalidOptions: Array<string>
) => {
  const paymentMethodOptions: IBillingShippingOptions = deepCopy(shippingOptions);
  paymentMethodOptions.paymentMethods = paymentMethodOptions.paymentMethods.map((paymentMethod) => {
    if (invalidOptions.includes(paymentMethod.code)) {
      paymentMethod.showMethod = false;
    }
    return paymentMethod;
  });
  return paymentMethodOptions;
};

export const getOverLimitShippingMethods = (
  shippingMethodOptions: IBillingShippingOptions,
  salesOrder: IOrder
) => {
  // if invalid shipping methods exists then don't show them and throw error message to fix it
  const overLimitShippingMethods: Array<string> = [];
  const devicesInShipment = getDevicesInShipment(salesOrder);
  const overLmtFn = isShippingMethodOverLimit(shippingMethodOptions, devicesInShipment!);
  // hide all the invalid shipping methods
  if (shippingMethodOptions.billMyAccountShippingMethod) {
    const billMyAcc = overLmtFn('BILL_MY_ACCOUNT');
    // if bill my account is over limit then hide it
    if (billMyAcc) {
      overLimitShippingMethods.push('BILL_MY_ACCOUNT');
    }
  }

  if (shippingMethodOptions.otherShippingMethods) {
    Object.keys(shippingMethodOptions.otherShippingMethods).forEach((key) => {
      // check the validity of every shipping method and hide it if it's over limit
      if (overLmtFn(key)) {
        overLimitShippingMethods.push(key);
      }
    });
  }

  return overLimitShippingMethods;
};

export const hideInvalidShippingMethodOptions = (
  shippingOptions: IBillingShippingOptions,
  invalidOptions: Array<string>
) => {
  const shippingMethodOptions = deepCopy(shippingOptions);
  // hide bill my account if it is invalid
  if (
    shippingMethodOptions.billMyAccountShippingMethod &&
    invalidOptions.includes('BILL_MY_ACCOUNT')
  ) {
    shippingMethodOptions.billMyAccountShippingMethod.showMethod = false;
  }

  if (shippingMethodOptions.otherShippingMethods) {
    Object.keys(shippingMethodOptions.otherShippingMethods).forEach((key) => {
      // check the validity of every shipping method and hide it if it's over limit
      if (invalidOptions.includes(key)) {
        shippingMethodOptions.otherShippingMethods[key].showMethod = false;
      }
    });
  }
  return shippingMethodOptions;
};

const isPaymentMethodOverLimit = (subTotal: number, payload: IPaymentMethodPayload) => {
  return !checkNullOrUndefined(payload.creditLimit) && subTotal > payload.creditLimit!;
};

export const getOrderSubTotal = (order: IOrder) =>
  order.items.reduce((acc, item) => acc + item.extendedPrice, 0);

export const getDevicesInShipment = (salesOrder: IOrder) =>
  salesOrder.items.reduce((acc, item) => acc + item.orderedQuantity - item.canceledQuantity, 0);

export const isCancellationFeesApplicable = (salesOrder: IOrder | undefined) =>
  salesOrder &&
  salesOrder?.racFeeAgreed &&
  // check if at least one day has passed since the grace period was over.
  differenceInSeconds(new Date(), new Date(salesOrder?.gracePeriodEnd)) >= 1 &&
  salesOrder?.cancellationFeePercent &&
  salesOrder?.cancellationFeePercent > 0 &&
  (checkNullOrUndefined(salesOrder?.racFeePeriodEnd) ||
    isFuture(new Date(salesOrder?.racFeePeriodEnd)));

export const isOrderCancelled = (lineItems: ILineItem[]) => {
  if (!lineItems.length) return true;
  return lineItems.every((lineItem) => lineItem.orderedQuantity === lineItem.canceledQuantity);
};

export const isValidCancellationFee = (
  cancellationFee: number | null,
  prevCancellationFee: number | null
) => {
  return (
    cancellationFee &&
    cancellationFee > 0 &&
    prevCancellationFee !== cancellationFee &&
    cancellationFee > prevCancellationFee!
  );
};

export const UpsertDefaultErrorMessageMap = {
  CANCEL: {
    code: 'errorCancellingOrder',
    message: 'Error while cancelling Order',
  },
  FULFILLMENT_STATUS: {
    code: 'errorUpdatingFulfillmentStatus',
    message: 'Error while updating Fulfillment Status',
  },
  PAYMENT_STATUS: {
    code: 'errorUpdatingPaymentStatus',
    message: 'Error while updating Payment Status',
  },
  UPSERT: {
    code: 'someChangesFailedToSave',
    message: 'Some of the changes failed to save',
  },
  SPLIT_COMBINE: {
    code: 'errorFailedToMoveItems',
    message: 'ERROR: Failed to move items',
  },
} as const;

const UpsertErrorMessageMap = {
  outdatedSalesOrder:
    'Your order was recently updated and your changes are not up to date. Please review your changes and try again.',
} as const;
type UpsertErrorMessageMapKeys = keyof typeof UpsertErrorMessageMap;

export const i18nErrorMessageWrapper = (I18n: any) => (
  error: OrderUpsertError,
  defaultMessage: Record<string, string>
) => {
  const transformedMessage = I18n[defaultMessage.code] || defaultMessage.message;

  if (!error.errorBody || !error.errorBody.errors.length) {
    return `${transformedMessage}: ${error.message}`;
  }

  const customMessage = error.errorBody.errors.find(
    (error) => error.messageCode in UpsertErrorMessageMap
  );

  if (customMessage) {
    return (
      I18n[customMessage.messageCode] ||
      UpsertErrorMessageMap[customMessage.messageCode as UpsertErrorMessageMapKeys]
    );
  }

  const { messageCode, messageDescription } = error.errorBody.errors[0];
  const message = I18n[messageCode] || messageDescription || messageCode;
  return `${transformedMessage}: ${message}`;
};

export const payAndShipDefaultDialogContent = (
  I18n: any,
  payShipNowHoldOrderAvailable: boolean,
  beforeReleasePermissions: Array<string>
) => {
  let content: any =
    I18n.areReadyReleaseOrderShip ||
    'Are you ready to release this order and have it shipped now? (You will not be able to add more items to this order.)';
  if (payShipNowHoldOrderAvailable) {
    let holdOrderLaterDesc =
      I18n.releaseOrderConfirmationDialogHoldOrderForLaterDescription ||
      'HOLD ORDER FOR LATER: This allows you to accumulate inventory for a few days to create larger orders. HOLD ORDER FOR LATER reserves inventory for you and creating an ON HOLD order is a commitment to purchase the inventory that is placed ON HOLD. ';
    if (!beforeReleasePermissions.includes('canCancelOrder')) {
      holdOrderLaterDesc +=
        I18n.releaseOrderConfirmationDialogCancellableHoldOrderForLaterDescription ||
        'You will not be able to Cancel ON HOLD Orders or inventory.';
    }
    content = (
      <>
        <p>
          {I18n.releaseOrderConfirmationDialogHowWouldYouLikeToProceedQuestion ||
            'There are two options to secure inventory and create an Order. How would you like to proceed?'}
        </p>
        <p className="px-text-description">{holdOrderLaterDesc}</p>
        <p className="px-text-description">
          {I18n.releaseOrderConfirmationDialogPayAndShipNowDescription ||
            'PAY AND SHIP NOW: Your order will be Checked Out and Awaiting Payment. You will not be able to add additional items to this Order.'}
        </p>
      </>
    );
  }

  return content;
};

export const getShippingAddressIdRealTimeShippingMethodMapping = (
  orderOptions: OrderOptions
): ShippingAddressIdRealTimeShippingMethodMapping => {
  const realTimeQuoteShippingMethods = orderOptions.realTimeQuoteShippingMethods;

  return Object.entries(orderOptions.shippingAddressIdShippingMethodCodeMapping || {}).reduce(
    (acc, [addressId, allowedMethods]) => {
      acc[addressId] = allowedMethods.filter(
        (method) => realTimeQuoteShippingMethods && method in realTimeQuoteShippingMethods
      );
      return acc;
    },
    {} as ShippingAddressIdRealTimeShippingMethodMapping
  );
};

export const updateFulfillmentMethodLabelBasedOnFees = (
  orderOptions: OrderOptions,
  fulfillmentCharges: any,
  requestPayload: any
): OrderOptions => {
  const updatedFulfillmentMethods = { ...orderOptions.fulfillmentMethods };
  const fulfillmentMethod = requestPayload?.fulfillmentMethodCode;
  if (
    fulfillmentCharges &&
    fulfillmentMethod &&
    fulfillmentMethod in orderOptions?.fulfillmentMethods
  ) {
    const methodObj = updatedFulfillmentMethods[fulfillmentMethod];
    updatedFulfillmentMethods[fulfillmentMethod].customLabel = `${
      methodObj.label
    } - ${dataFormatting('currency', fulfillmentCharges?.fulfillmentFee, true)}`;
  }
  return { ...orderOptions, fulfillmentMethods: updatedFulfillmentMethods };
};

export const hideRealTimeQuoteShippingMethodsBasedOnAvailability = (
  I18n: any,
  orderOptions: OrderOptions,
  freightQuotesForAllRealTimeShippingMethods: Record<string, number> | null,
  currentSelection: {
    shippingAddress: OrderOptions['shippingAddresses'][number];
    shippingMethodCode: string;
  }
): OrderOptions => {
  const updatedOrderOptions = { ...orderOptions };
  const availableRealTimeShippingMethods =
    orderOptions.shippingAddressIdRealTimeShippingMethodMapping?.[
      currentSelection.shippingAddress?.id
    ] || [];
  const isRealTimeMethodSupported = availableRealTimeShippingMethods.length > 0;
  if (isRealTimeMethodSupported) {
    let hasAtleastOneRealTimeMethodAvailable = false;

    availableRealTimeShippingMethods.forEach((methodCode) => {
      // quote is available for the method
      const isQuoteAvailable = !!(
        freightQuotesForAllRealTimeShippingMethods &&
        methodCode in freightQuotesForAllRealTimeShippingMethods
      );

      if (methodCode in updatedOrderOptions.otherShippingMethods) {
        // perform side effects if quote is available
        if (isQuoteAvailable) {
          const cost = freightQuotesForAllRealTimeShippingMethods?.[methodCode]
            ? I18n._formatType(
                freightQuotesForAllRealTimeShippingMethods[methodCode],
                'currency-string'
              )
            : I18n.freeShipping || 'Free Shipping';
          updatedOrderOptions.otherShippingMethods[
            methodCode
          ].customLabel = `${orderOptions.realTimeQuoteShippingMethods[methodCode].label} - ${cost}`;
        }

        // can show method only if quote is available.
        updatedOrderOptions.otherShippingMethods[methodCode].showMethod = isQuoteAvailable;
      }

      hasAtleastOneRealTimeMethodAvailable =
        hasAtleastOneRealTimeMethodAvailable || isQuoteAvailable;
    });
    // show request a quote if no real time quote is available.
    if ('REQUEST_A_QUOTE' in updatedOrderOptions.otherShippingMethods) {
      updatedOrderOptions.otherShippingMethods[
        'REQUEST_A_QUOTE'
      ].showMethod = !hasAtleastOneRealTimeMethodAvailable;
    }
  } else {
    // if there is no realtime shipping method supported for the selected address,
    // disable all realtime shipping methods
    Object.keys(updatedOrderOptions.realTimeQuoteShippingMethods).forEach((methodCode) => {
      if (methodCode in updatedOrderOptions.otherShippingMethods) {
        updatedOrderOptions.otherShippingMethods[methodCode].showMethod = false;
      }
    });
    // show request a quote.
    if ('REQUEST_A_QUOTE' in updatedOrderOptions.otherShippingMethods) {
      updatedOrderOptions.otherShippingMethods['REQUEST_A_QUOTE'].showMethod = true;
    }
  }
  return updatedOrderOptions;
};

export const processReduxSalesOrderSettings = (settings: any) => {
  Object.keys(settings).forEach((participantId) => {
    settings[participantId].isExternalSystemManualSync = isAllViaPhonexAndHasExternalSystem(
      settings[participantId]?.salesOrderUpdateMethod
    );
    settings[participantId].singleButtonOrderFlow = !!settings[participantId].fulfillmentSettings
      .singleButtonOrderFlow;
    settings[participantId].enableOnholdForManualSyncTenant = !!settings[participantId]
      .fulfillmentSettings.enableOnholdForManualSyncTenant;
  });
  return settings;
};