import React, { ErrorInfo } from 'react';
import ErrorView from './ErrorView';
import { getErrorObj, getUIErrorObj, sendErrorToRum } from './ErrorUtils';

interface IPageLevelErrorBoundary {
  children: any;
  openSnackBar: any;
}

let oldHref = document.location.href;

class PageLevelErrorBoundary extends React.Component<IPageLevelErrorBoundary, { error: any }> {
  constructor(props: IPageLevelErrorBoundary) {
    super(props);
    this.state = { error: undefined };
    this.setError = this.setError.bind(this);
    this.handleRejection = this.handleRejection.bind(this);
  }

  setError(res: any) {
    this.setState({ error: getErrorObj(res) });
  }

  static getDerivedStateFromError(error: any) {
    return { error: getUIErrorObj(error) };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setError({
      reason: getUIErrorObj(error),
    });
    try {
      sendErrorToRum(
        new Error(
          JSON.stringify({
            reason: error.message,
            unhandledrejection: false,
            uiInterrupted: true,
            stack: error?.stack,
            levelOfError: 'PAGE_LEVEL',
          })
        )
      );
    } catch (err) {
      sendErrorToRum(err);
    }
  }

  handleRejection(event: PromiseRejectionEvent) {
    if (event.reason && event.reason.name === 'NetworkError') this.setError(event);
    try {
      sendErrorToRum(
        new Error(
          JSON.stringify({
            reason: JSON.stringify(event.reason),
            unhandledrejection: true,
            uiInterrupted: !!event.reason && event.reason.name === 'NetworkError',
            levelOfError: 'PAGE_LEVEL',
          })
        )
      );
    } catch (err) {
      sendErrorToRum(err);
    }
  }

  componentDidMount(): void {
    window.addEventListener('unhandledrejection', this.handleRejection);
    const that = this;
    const urlMutationObserver = new MutationObserver(function (mutations: MutationRecord[]) {
      mutations.forEach(function (mutation) {
        if (oldHref !== document.location.href) {
          oldHref = document.location.href;
          if (that.state.error) that.setState({ error: undefined });
        }
      });
    });
    urlMutationObserver.observe(document.querySelector('body')!, {
      childList: true,
      subtree: true,
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.handleRejection);
  }

  render(): React.ReactNode {
    if (this.state.error)
      return <ErrorView openSnackBar={this.props.openSnackBar} error={this.state.error} />;
    return this.props.children;
  }
}
export default PageLevelErrorBoundary;
