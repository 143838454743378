import {
  FetchQueryOptions,
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { triggerBotActivityEvent } from '../../_lib/util';

export const publicApiMapping = {
  config: '/saas-config',
  i18n: '/saas-i18n/v1',
  salesRep: '/buyer-onboarding/sales-reps',
  resetPassword: '/saas-login/reset-password',
};

const getPublicDataEndpoint = (env: any) => {
  let publicDataEndpoint;
  publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway';
  if (!process.env.REACT_APP_API_URL)
    switch (env) {
      case 'test':
        publicDataEndpoint =
          'https://fyzamyl7yi.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway';
        break;
      case 'prod':
        publicDataEndpoint =
          'https://qkexusfqqf.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway';
        break;
      case 'release':
        publicDataEndpoint =
          'https://94o107xk88.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway';
        break;
      default:
      // publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway';
    }
  return publicDataEndpoint;
};

export const fetchPublic = async ({ queryKey, meta }: any, preprocessFetchedData?: any) => {
  try {
    const [path] = queryKey;
    const publicDataEndpoint = getPublicDataEndpoint(process.env.REACT_APP_STAGE) + path;
    const res = await fetch(publicDataEndpoint, {
      method: 'GET',
      ...meta,
    });
    if (preprocessFetchedData) return preprocessFetchedData(res);
    const data = await res.json();
    if (data && data.status === 200) return data.results;
    else return {};
  } catch (error) {
    triggerBotActivityEvent(error)
    return {};
  }
};

interface IFetchQuery extends FetchQueryOptions {
  url: string;
  preprocessFetchedData?:(data: any) => any;
}

export const fetchQuery = (queryClient: QueryClient) => async ({ url, preprocessFetchedData, ...rest }: IFetchQuery) => {
  return await queryClient.fetchQuery({
    queryKey: [url],
    queryFn: (queryOption) => fetchPublic(queryOption, preprocessFetchedData),
    retry: false,
    ...rest,
  });
};

interface IUsePublicQueryStates<T> extends UseQueryOptions {
  url: string;
  additionalFetchParams?: Record<string, any>;
  preprocessFetchedData?: (data: any) => T;
}

export const usePublicQueryStates = <T,>(params: IUsePublicQueryStates<T>): UseQueryResult<T> => {
  return useQuery({
    queryKey: [params.url],
    queryFn: (queryoption) => fetchPublic(queryoption, params.preprocessFetchedData),
    refetchOnWindowFocus: false,
    retry: false,
    ...params,
  });
};
