export const CAN_VIEW_AUCTION_SUMMARY = 'CAN_VIEW_AUCTION_SUMMARY';
export const CAN_VIEW_STOCK_LIST = 'CAN_VIEW_STOCK_LIST';
export const CAN_VIEW_ORDER = 'CAN_VIEW_ORDER';
export const CAN_UPDATE_ORDER = 'CAN_UPDATE_ORDER';
export const CAN_VIEW_RETURNS = 'CAN_VIEW_RETURNS';
export const CAN_PREORDER = 'CAN_PREORDER';

export const SideMenuMap: any = {
  CAN_VIEW_AUCTION: 'Auctions',
  CAN_BID_IN_AUCTION: 'Auctions',
  CAN_VIEW_ORDER: 'Orders',
  CAN_UPDATE_ORDER: 'Orders',
  CAN_VIEW_RETURNS: 'Returns',
  CAN_BUY_ONLINE: 'Cart',
  CAN_PREORDER: 'Preorders',
  CAN_CREATE_RMA: 'RMA',
};
