import React, { Suspense, useContext, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';

import Theme from './Theme';

// LAYOUTS
import FrameLayout from './layouts/layout-dashboard-frame';

// VIEWS
import Homepage from './views/Homepage/Homepage';
import Stock from './views/Stock/Stock';
// ALL
import TopMenu from './components/TopMenu/TopMenu';
import Progress from './components/Progress/Progress';

//ERROR HANDLER
import useDataService from './hooks/useDataService';

import apiToUrlMap from './ApiMapping';
import {
  accountsetupPrivateRoute,
  defaultParticipant,
  publicPrivateRoute,
  rmaRoutesMap,
} from './_lib/constant';
import Footer from './components/Footer/Footer';
import ProofOfExportDialog from './components/ProofOfExportDialog/ProofOfExportDialog';
import Widgets from './widgets';
import DeactivatedTenant from './widgets/PersistedScreens/DeactivatedTenant';
import {
  getPXNWarehouseObj,
  logoutUserWithoutAcknowledgement,
  checkNullOrUndefined,
  getNetworkSystemMessageState,
  processReduxStockListSettings,
} from './_lib/util';
import ErrorView from './components/ErrorHandlers/ErrorView';
import PublicAndPrivateRoute from './routes/PublicAndPrivateRoute';
import { I18nContext } from './I18n';
import { isViewOnlyCustomer, viewOnlyCustomerPathValid } from './_lib/tagUtil';
import FullWidthLayout from './layouts/layout-dashboard-fullwidth';
import {
  missingDocumentNotification,
  missingRelatedStatus,
} from './components/AccountSetup/AccountSetupUtils';
import AccountSetupPrivate from './routes/AccountSetupPrivate';

import FiltersPersistWrapper from './components/FiltersPersistWrapper/FiltersPersistWrapper';

import { pathOverrideKeys, useGetDataService } from './hooks/useQueryCustomHooks/useDataService';
import { processReduxSalesOrderSettings } from './components/OrdersComponents/OrderUtils';
import { publicApiMapping } from './hooks/useQueryCustomHooks/usePublicQueryStates';
import { useGetQueryCacheData } from './hooks/useQueryCustomHooks/useGetQueryCacheData';
import {
  useDateFormatter,
  useSetDateFormatter,
} from './components/DateFormatWrapper/DateFormatWrapper';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCountryAndStateCodes } from './hooks/useQueryCustomHooks/useCountryAndStateCodes';
import useGetProofOfExport from './hooks/useQueryCustomHooks/useGetProofOfExport';
import { ParticipantWarehouse } from './types/StockListTypes';
import { IUserInfo } from './types/IUserInfo';
const ComplianceQuestionnaire = React.lazy(
  () => import('./views/ComplianceQuestionnaire/ComplianceQuestionnaire')
);
const Login = React.lazy(() => import('./components/Login/AuthWrapper'));
const OrderConfirmation = React.lazy(() => import('./views/OrderConfirmation/OrderConfirmation'));
const ResetPassword = React.lazy(() => import('./components/Login/ResetPasswordFlow'));
const PaymentConfirmation = React.lazy(
  () => import('./views/PaymentConfirmation/PaymentConfirmation')
);
const AccountSetupDocumentsV1 = React.lazy(
  () => import('./views/AccountSetupDocuments/AccountSetupDocumentsV1')
);
const ShippingConsentForm = React.lazy(
  () => import('./views/ShippingConsentForm/ShippingConsentForm')
);
const CheckoutConfirmationV1 = React.lazy(
  () => import('./views/CheckoutConfirmation/CheckoutConfirmationV1')
);
const CheckoutV1 = React.lazy(() => import('./views/Checkout/CheckoutV1'));
const AddToExistingOrderV1 = React.lazy(
  () => import('./views/AddToExistingOrder/AddToExistingOrderV1')
);
const CartV1 = React.lazy(() => import('./views/Cart/CartV1'));
const OrdersV1 = React.lazy(() => import('./views/Orders/OrdersV1'));
const OrderDetailsV1 = React.lazy(() => import('./views/Orders/OrderDetailsV1'));
const AuctionBidding = React.lazy(() => import('./views/AuctionBidding/AuctionBidding'));
const MasterPurchaseAgreement = React.lazy(
  () => import('./views/MasterPurchaseAgreement/MasterPurchaseAgreement')
);
const AuctionList = React.lazy(() => import('./views/AuctionList/AuctionList'));
const CreateAccount = React.lazy(() => import('./views/CreateAccount/CreateAccount'));
const EmailVerfication = React.lazy(
  () => import('./components/EmailVerification/EmailVerification')
);
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const StockAlerts = React.lazy(() => import('./views/StockAlerts/StockAlerts'));
const Terms = React.lazy(() => import('./views/Terms/Terms'));
const Returns = React.lazy(() => import('./views/Returns/Returns'));
const Account = React.lazy(() => import('./views/Account/Account'));
const PoAwards = React.lazy(() => import('./views/POFulfillment/PoAwards'));
const RmaRequest = React.lazy(() => import('./views/RMARequest/RmaRequest'));
const RMARequestDetails = React.lazy(() => import('./views/RMARequestDetails/RMARequestDetails'));
const AccountSetupDetails = React.lazy(
  () => import('./views/AccountSetupDetails/AccountSetupDetails')
);
const AccountBalance = React.lazy(() => import('./views/AccountBalance/AccountBalance'));
const AccountSetupDocuments = React.lazy(
  () => import('./views/AccountSetupDocuments/AccountSetupDocuments')
);
const AccountSetup = React.lazy(() => import('./views/AccountSetup/AccountSetup'));
const AccountSetupEUS = React.lazy(() => import('./views/AccountSetupEUS/AccountSetupEUS'));
const AccountSetupEUSAddendum = React.lazy(
  () => import('./views/AccountSetupEUSAddendum/AccountSetupEUSAddendum')
);
const AccountSetupEUSV2 = React.lazy(() => import('./views/AccountSetupEUS/AccountSetupEUSV2'));
const AccountSetupEUSV1 = React.lazy(() => import('./views/AccountSetupEUS/AccountSetupEUSV1'));
const ShippingAndBillingPreferences = React.lazy(
  () => import('./views/ShippingAndBillingPreferences/ShippingAndBillingPreferences')
);

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-021533,ExpiryDate=23_March_2023_[v2]_MTY3OTUyOTYwMDAwMA==eab206f6e7f556e6d4c7a0231d3d58b4'
);
const PrivateRoute = ({ children, fullWidth, ...rest }: any) => {
  const I18n = useContext(I18nContext);
  const setDateFormatter = useSetDateFormatter();
  const { openSnackBar, closeSnackBar } = useDataService();
  const [settingsApiErr, setSettingsApiErr] = useState<any>();
  const history = useHistory();
  const queryClient = useQueryClient();
  history.listen((location: any) => {
    if (!location.search) window.scrollTo(0, 0);
  });

  const forceLogout = new URLSearchParams(history.location.search).get('force-logout') === 'true';
  const dateFormatter = useDateFormatter();

  const userState = rest.redux.props.userState; // Data from cache

  //move userInfo to react query
  const baseCondition =
    !checkNullOrUndefined(userState.userInfo?.setupCompleted) &&
    !!userState.isSignedIn &&
    !forceLogout;

  useCountryAndStateCodes(baseCondition);

  useGetDataService({
    url: apiToUrlMap.cartCheckout,
    overrideQueryKey: pathOverrideKeys[apiToUrlMap.cartCheckout],
    enabled: false,
    initialData: {},
  });

  useGetDataService({
    url: '',
    overrideQueryKey: pathOverrideKeys.orderCompute,
    enabled: false,
    initialData: {
      cartSubtotal: 0,
      additionalServiceFees: null,
      freight: null,
      tax: null,
      fees: null,
      cartTotalQuantity: 0,
      existingOrderSubtotal: 0,
      existingOrderTotalQuantity: 0,
      combinedSubtotal: 0,
      combinedTotalQuantity: 0,
      totalPrice: 0,
      taxDescription: '',
      metadata: {
        feesApplicable: null,
        feeType: null,
        fees: null,
      },
    },
  });

  // fetch broadcase banner
  useGetDataService({
    url: apiToUrlMap.broadcastBanner,
    queryParams: {
      transformData: (broadcastBanner) => {
        if (broadcastBanner && broadcastBanner[0]?.active) {
          return broadcastBanner.map((details: any) => ({
            ...details,
            isBannerActive: details.active,
          }));
        }
        return broadcastBanner;
      },
    },
    enabled: baseCondition && !!localStorage.getItem('pxFetchBroadcastBanner'),
    onSuccess: () => {
      localStorage.removeItem('pxFetchBroadcastBanner');
    },
    onError: (error) => {
      console.error(error);
    },
  });

  //fetch missing document
  useGetDataService({
    url: apiToUrlMap.accountDocuments,
    queryParams: {
      additionalParams: {
        headers: { buyerType: userState.userInfo?.buyerType },
      },
    },
    enabled: baseCondition && !!localStorage.getItem('pxMissingDocumentsSnackbar'),
    onSuccess: (missingDocuments: any) => {
      localStorage.removeItem('pxMissingDocumentsSnackbar');
      const countMissingDocuments = missingDocuments.reduce(
        (acc: number, document: any) =>
          missingRelatedStatus.includes(document.documentStatus) ? acc + 1 : acc,
        0
      );
      if (countMissingDocuments)
        missingDocumentNotification({
          openSnackBar,
          closeSnackBar,
          history,
          I18n,
          cb: () => {
            setTimeout(() => {
              queryClient.setQueryData([apiToUrlMap.broadcastBanner], (prev: any) => {
                const bannerPayload = [...prev];
                bannerPayload.map((details) => (details.isBannerActive = false));
                return [...prev, ...bannerPayload];
              });
            }, 0);
          },
        });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useGetProofOfExport({
    onError: (error) => {
      console.error('error in fetching proof of export', error);
      if (error.status === 403) return;
      setSettingsApiErr(error);
    },
    enabled: userState?.userInfo?.setupCompleted,
  });

  //fetch inventory settings
  useGetDataService({
    url: apiToUrlMap.inventorySettings,
    enabled: baseCondition,
    onError: (error) => {
      console.error(error);
    },
  });

  // fetch cart settings
  useGetDataService({
    url: apiToUrlMap.getCartSettings,
    enabled: baseCondition,
    onError: (error) => {
      console.error(error);
    },
  });

  const showInviteProspectExpirationInfo = () => {
    const displayIPSnackbar = localStorage.getItem('pxProspectInviteExpirationSnackbar');
    const expirationDate = userState.userInfo?.stocklistAccessExpirationDate;
    if (!displayIPSnackbar || !expirationDate) return;
    openSnackBar(
      <>
        Click{' '}
        <span
          className="mouse-pointer margin-bottom-0"
          onClick={() => {
            history.push('/account-setup');
            closeSnackBar();
          }}
        >
          HERE
        </span>{' '}
        to complete your registration and keep your access. Access will expire on{' '}
        {dateFormatter(expirationDate, 'SHORT_DATE')}
      </>,
      'warning'
    );
    localStorage.removeItem('pxProspectInviteExpirationSnackbar');
  };

  const configData = rest.redux.props.configState;
  const networkBaseCondition = !!configData.networkParticipant;

  const { data: pxnSettings }: any = useGetDataService({
    url: apiToUrlMap.pxnSettings,
    initialData: () => {
      if (!networkBaseCondition) {
        return {
          isPxns: false,
          pxnrConfig: {},
          pxnrParticipantId: defaultParticipant,
          pxnrConfirmed: false,
        };
      }
    },
    enabled: baseCondition && networkBaseCondition,
    onError: (error) => {
      console.error(error);
    },
  });

  //fetch pxn warehouses
  useGetDataService<Record<string | number, ParticipantWarehouse>>({
    url: apiToUrlMap.pxnWarehouses,
    enabled: baseCondition && networkBaseCondition && !!pxnSettings?.isPxnr,
    queryParams: {
      transformData: (warehouses) => getPXNWarehouseObj(warehouses, userState.userInfo),
    },
    onError: (error) => {
      console.error(error);
    },
  });

  //fetch pxn stocklist settings
  useGetDataService({
    url: apiToUrlMap.pxnStockSettings,
    overrideQueryKey: pathOverrideKeys[apiToUrlMap.pxnStockSettings],
    enabled: baseCondition && networkBaseCondition && !!pxnSettings?.isPxnr,
    queryParams: {
      transformData: async (stocklistSettings) => {
        const transformedData = {
          pxnSettings: {
            ...pxnSettings,
            pxnrParticipantId: `${pxnSettings.pxnrParticipantId}`,
          },
          settings: processReduxStockListSettings(stocklistSettings),
          networkSystemMessages: getNetworkSystemMessageState(stocklistSettings),
        };
        return transformedData;
      },
    },
    onError: (error) => {
      console.error(error);
    },
  });

  //fetch pxn order settings
  useGetDataService({
    url: apiToUrlMap.pxnOrderSettings,
    overrideQueryKey: pathOverrideKeys[apiToUrlMap.pxnOrderSettings],
    queryParams: {
      transformData: (salesOrderSettings) => {
        const transformedSalesOrderSettings = processReduxSalesOrderSettings(salesOrderSettings);

        return transformedSalesOrderSettings;
      },
    },
    enabled: baseCondition && networkBaseCondition && !!pxnSettings?.isPxnr,
    onError: (error) => {
      console.error(error);
    },
  });

  //fetch order settings
  useGetDataService({
    url: apiToUrlMap.orderSettings,
    overrideQueryKey: pathOverrideKeys[apiToUrlMap.orderSettings],
    enabled: baseCondition && (!networkBaseCondition || (!!pxnSettings && !pxnSettings?.isPxnr)),
    queryParams: {
      transformData: (orderSettings) => {
        const transformedData = {
          [defaultParticipant]: orderSettings,
        };
        const transformedSalesOrderSettings = processReduxSalesOrderSettings(transformedData);

        return transformedSalesOrderSettings;
      },
    },
    onError: (error) => {
      console.error(error);
    },
  });

  //fetch stocklist settings
  const { data: stockListSettings } = useGetDataService<any>({
    url: apiToUrlMap.stockListSettings,
    overrideQueryKey: pathOverrideKeys[apiToUrlMap.stockListSettings],
    enabled: baseCondition && (!networkBaseCondition || (!!pxnSettings && !pxnSettings?.isPxnr)),
    queryParams: {
      transformData: async (stockListSettings) => {
        const pxnSettings: any = {
          isPxns: false,
          pxnrConfig: {},
          pxnrParticipantId: defaultParticipant,
          pxnrConfirmed: false,
        };

        const transformedData = {
          pxnSettings,
          settings: {
            [defaultParticipant]: stockListSettings,
          },
          networkSystemMessages: {
            [defaultParticipant]: stockListSettings?.cartSettings,
          },
        };

        transformedData.settings = processReduxStockListSettings(transformedData.settings);

        return transformedData;
      },
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // fetch display settings
  const { isFetched } = useGetDataService({
    url: apiToUrlMap.displaySettings,
    enabled: baseCondition,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: (settings: any) => {
      if (setDateFormatter) setDateFormatter(settings?.customDatePatterns);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  React.useEffect(() => {
    if (isFetched) {
      showInviteProspectExpirationInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  React.useEffect(() => {
    if (checkNullOrUndefined(userState.userInfo?.setupCompleted)) return;
    //show Orders and Offers notification banners
    localStorage.setItem('showStockListNotifications', 'true');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userInfo?.setupCompleted]);

  React.useEffect(() => {
    const forceLogout = new URLSearchParams(history.location.search).get('force-logout') === 'true';
    if (forceLogout) {
      logoutUserWithoutAcknowledgement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userState.isSignedIn && settingsApiErr)
    return <ErrorView openSnackBar={openSnackBar} error={settingsApiErr} />;

  if (
    (userState.isSignedIn &&
      !stockListSettings?.settings &&
      history.location.pathname !== '/stock') ||
    forceLogout
  ) {
    return <div className="px-full-screen-loader">{loading(I18n)}</div>;
  }

  if (userState?.userInfo?.isOfflineBuyer) {
    return (
      <Route
        {...rest}
        render={({ location }: any) => {
          if (rest.path.includes('/orders')) {
            return <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>;
          }

          return (
            <Redirect
              to={{
                pathname: '/orders',
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  if (isViewOnlyCustomer(userState?.userInfo?.enabledTags)) {
    return (
      <Route
        {...rest}
        render={({ location }: any) => {
          if (viewOnlyCustomerPathValid(rest.path)) {
            return <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>;
          }
          return <Redirect to="/stock" />;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userState.isSignedIn ? (
          fullWidth ? (
            <FullWidthLayout isMenuOpened={rest.isMenuOpened}>{children}</FullWidthLayout>
          ) : (
            <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const HomeRoute = ({ children, ...rest }: any) => {
  const userState = rest.redux.props.userState; // Data from cache

  return (
    <Route
      {...rest}
      render={({ location }: any) => {
        //if user requiresAccSetup = not signed in (even logged in)
        if (!userState.isSignedIn) {
          if (userState.gracePeriodBuyer) {
            return <Redirect to={{ pathname: '/account-details' }} />;
          }
          if (userState.requiresAccountSetup) {
            return <Redirect to={{ pathname: '/account-setup' }} />;
          }
          return (
            <Homepage>
              <Login />
            </Homepage>
          );
        }

        if (userState?.userInfo?.isOfflineBuyer) {
          return (
            <Redirect
              to={{
                pathname:
                  location.state && location.state.from.pathname.includes('/orders')
                    ? `${location.state.from.pathname}`
                    : '/orders',
                search: location.state ? location.state.from.search : '',
                state: { from: location },
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname:
                location.state &&
                !Object.values(accountsetupPrivateRoute).includes(location.state.from.pathname)
                  ? `${location.state.from.pathname}`
                  : '/stock',
              search: location.state ? location.state.from.search : '',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const ResetPasswordRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Homepage>
            <ResetPassword />
          </Homepage>
        );
      }}
    />
  );
};
const CreateAccountRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Suspense fallback={<></>}>
            <Homepage pxFrameView={true}>
              <CreateAccount />
            </Homepage>
          </Suspense>
        );
      }}
    />
  );
};

const EmailVerficationRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Suspense fallback={<></>}>
            <Homepage pxFrameView={true}>
              <EmailVerfication />
            </Homepage>
          </Suspense>
        );
      }}
    />
  );
};

const PxRouter = (props: any) => {
  const setCustomCSS = async (config: any) => {
    const style = document.createElement('style');
    style.innerHTML = config.custom_css;
    document.documentElement.appendChild(style);
  };

  useGetDataService({
    url: '',
    overrideQueryKey: pathOverrideKeys.pusherEvent,
    enabled: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useGetDataService({
    url: '',
    overrideQueryKey: pathOverrideKeys.analyticsEvents,
    enabled: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  React.useEffect(() => {
    if (props.props.configState) setCustomCSS(props.props.configState);
  }, [props.props.configState]);

  return (
    <Theme redux={props}>
      <Suspense fallback={<></>}>
        <Router>
          <div className="cell small-12 px-sass-container grid-x">
            <ProofOfExportDialog />
            <header className="cell small-12 px-header">
              <TopMenu />
            </header>
            <div className="cell small-12">
              <Widgets />
              <Switch>
                <PrivateRoute path="/dashboard" redux={props} isMenuOpened={0}>
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/stock" redux={props} isMenuOpened={1}>
                  <FiltersPersistWrapper localStorageKey="stocklistFilters" path="/stock">
                    <Stock />
                  </FiltersPersistWrapper>
                </PrivateRoute>
                <PrivateRoute path="/stock-alerts" redux={props} isMenuOpened={1}>
                  <StockAlerts />
                </PrivateRoute>
                <PrivateRoute path="/po-awards" redux={props} isMenuOpened={1} fullWidth={true}>
                  <PoAwards />
                </PrivateRoute>
                <PrivateRoute
                  path={`${rmaRoutesMap.rmaRequests}/:rmaRequestId`}
                  redux={props}
                  isMenuOpened={1}
                  fullWidth={true}
                >
                  <RMARequestDetails />
                </PrivateRoute>
                <PrivateRoute
                  path={rmaRoutesMap.rmaRequests}
                  redux={props}
                  isMenuOpened={1}
                  fullWidth={true}
                >
                  <RmaRequest />
                </PrivateRoute>
                <PrivateRoute
                  path="/orders/order-release-confirmation"
                  redux={props}
                  exact={1}
                  isMenuOpened={1}
                >
                  <OrderConfirmation />
                </PrivateRoute>
                <PrivateRoute path="/orders/:id" exact={true} redux={props} isMenuOpened={1}>
                  <OrderDetailsV1 />
                </PrivateRoute>
                <PrivateRoute path="/orders" exact redux={props} isMenuOpened={1}>
                  <OrdersV1 />
                </PrivateRoute>
                <PrivateRoute path="/returns" redux={props} isMenuOpened={1}>
                  <Returns />
                </PrivateRoute>
                <PrivateRoute path="/account" redux={props} isMenuOpened={1}>
                  <Account />
                </PrivateRoute>
                <PrivateRoute
                  path="/account-details/account-balance"
                  redux={props}
                  isMenuOpened={1}
                >
                  <AccountBalance />
                </PrivateRoute>
                <PrivateRoute path="/cart" redux={props} isMenuOpened={1}>
                  <CartV1 />
                </PrivateRoute>
                <PrivateRoute
                  path="/checkout/order-release-confirmation"
                  redux={props}
                  isMenuOpened={1}
                >
                  <CheckoutConfirmationV1 />
                </PrivateRoute>
                <PrivateRoute
                  path="/checkout/add-to-unshipped-order/:warehouse"
                  redux={props}
                  isMenuOpened={1}
                  exact
                >
                  <AddToExistingOrderV1 />
                </PrivateRoute>
                <PrivateRoute
                  path="/orders/:phonexOrderNumber/order-release-confirmation"
                  redux={props}
                  isMenuOpened={1}
                >
                  <PaymentConfirmation />
                </PrivateRoute>
                <PrivateRoute
                  path="/checkout/add-to-unshipped-order/:warehouse/:salesOrder"
                  redux={props}
                  isMenuOpened={1}
                >
                  <CheckoutV1 />
                </PrivateRoute>
                <PrivateRoute path="/checkout/:warehouse" redux={props} isMenuOpened={1}>
                  <CheckoutV1 />
                </PrivateRoute>
                <PrivateRoute path="/preferences" redux={props} isMenuOpened={1}>
                  <ShippingAndBillingPreferences />
                </PrivateRoute>
                <PrivateRoute path="/terms-of-sale" redux={props} isMenuOpened={1}>
                  <Terms />
                </PrivateRoute>
                <AccountSetupPrivate
                  path={accountsetupPrivateRoute.accountSetup}
                  redux={props}
                  exact
                >
                  <AccountSetup />
                </AccountSetupPrivate>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.masterAgreementForm}
                  redux={props}
                  exact={true}
                >
                  <MasterPurchaseAgreement />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.freightForwarderv2}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupDocumentsV1 />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.freightForwarder}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupDocuments />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.addendum}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupEUSAddendum />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute path={publicPrivateRoute.eus} redux={props} exact={true}>
                  <AccountSetupEUS />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute path={publicPrivateRoute.eusv1} redux={props} exact={true}>
                  <AccountSetupEUSV1 />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute path={publicPrivateRoute.eusv2} redux={props} exact={true}>
                  <AccountSetupEUSV2 />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.shippingConsentForm}
                  redux={props}
                  exact={true}
                >
                  <ShippingConsentForm />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.accountDetails}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupDetails />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.complianceQuestionnaire}
                  redux={props}
                  exact={true}
                >
                  <ComplianceQuestionnaire />
                </PublicAndPrivateRoute>
                <PrivateRoute path="/auctions" redux={props} exact={true}>
                  <AuctionList />
                </PrivateRoute>
                <PrivateRoute path="/auctions/:auctionNumber" redux={props} exact={true}>
                  <AuctionBidding />
                </PrivateRoute>
                <CreateAccountRoute path="/create-account" />
                <EmailVerficationRoute path="/email-verification" />
                <ResetPasswordRoute path="/reset-password" redux={props} />
                <HomeRoute path="/" redux={props} />
              </Switch>
            </div>
            <footer className="cell small-12">
              <Footer />
            </footer>
          </div>
        </Router>
      </Suspense>
    </Theme>
  );
};

export const loading = (I18n?: any) => (
  <>
    {I18n ? (
      <h3 className="text-center">{I18n.loading || 'Loading'}...</h3>
    ) : (
      <div className="margin-top-2">&nbsp;</div>
    )}
    <Progress />
  </>
);

function PhonexRouter(props: any) {
  // useFetchConfig();
  const userState = useGetQueryCacheData(apiToUrlMap.getUserInfo) as IUserInfo;
  const configState = useGetQueryCacheData<any>(publicApiMapping.config);

  return (
    <div className="cell grid-x align-center">
      {configState?.deactivated && (
        <DeactivatedTenant
          content={`This site is no longer available. Please contact ${configState.tenantName} for more information.`}
        />
      )}
      {configState && !configState.deactivated && userState ? (
        <PxRouter props={{ ...props, userState, configState }} />
      ) : (
        loading()
      )}
    </div>
  );
}

export default PhonexRouter;
