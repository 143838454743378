import React from 'react';
import StockListItem from './StockListItem';
import './StockList.scss';
import { I18nContext } from '../../I18n';
import { canBuyOnlineFn, isViewOnlyCustomer } from '../../_lib/tagUtil';
import Progress from '../Progress/Progress';
import { useQueryClientService } from '../../hooks/useQueryCustomHooks/useDataService';
import { useGetQueryCacheData } from '../../hooks/useQueryCustomHooks/useGetQueryCacheData';
import apiToUrlMap from '../../ApiMapping';
import { IAuthority } from '../../hooks/useQueryCustomHooks/useGetUserInfoAndAuthorities';
import { IUserInfo } from '../../types/IUserInfo';

function StockList(props: any) {
  const I18n = React.useContext(I18nContext);
  const { setQueryCacheData } = useQueryClientService();
  const stockListOffers = useGetQueryCacheData(apiToUrlMap.offers);
  const authorities = useGetQueryCacheData<IAuthority>(apiToUrlMap.authorities)?.authorityObj;
  const { enabledTags, setupCompleted } = useGetQueryCacheData<IUserInfo>(
    apiToUrlMap.getUserInfo
  )?.userInfo as IUserInfo['userInfo'];

  const {
    pageDataState,
    stockListSettings,
    pxnSettings,
    stockAlerts,
    openGradePopup,
    isPxns,
    ...rest
  } = props;

  const stockOffersUpdate = (offers) => setQueryCacheData(apiToUrlMap.offers, offers);

  return (
    <>
      {pageDataState ? (
        pageDataState.length > 0 ? (
          <div className="grid-x">
            {pageDataState.map((group: any) => {
              return (
                <StockListItem
                  key={group.key}
                  group={group}
                  title={group.title}
                  stockListSettings={stockListSettings}
                  isPxns={isPxns}
                  {...rest}
                  offers={stockListOffers || {}}
                  stockOffersUpdate={stockOffersUpdate}
                  canUserBuy={canBuyOnlineFn(authorities)}
                  showBuyOnlineBtn={!isViewOnlyCustomer(enabledTags)}
                  canUserAlert={authorities['CAN_SET_STOCKLIST_ALERT']}
                  openGradePopup={openGradePopup}
                  setupCompleted={setupCompleted}
                />
              );
            })}
          </div>
        ) : (
          <div className="margin-vertical-3">{I18n.noItemsFound || 'No items found!'}</div>
        )
      ) : (
        <div className="margin-top-2">
          <Progress />
        </div>
      )}
    </>
  );
}

export default StockList;
