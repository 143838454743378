import React from 'react';
import { getUIErrorObj, sendErrorToRum } from './ErrorUtils';
import ErrorView from './ErrorView';
import './RootLevelErrorBoundary.scss';

class RootLevelErrorBoundary extends React.Component<{}, { error: any }> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error: getUIErrorObj(error) };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({ error: getUIErrorObj(error) });
    try {
      sendErrorToRum(
        new Error(
          JSON.stringify({
            reason: error?.message,
            unhandledrejection: false,
            uiInterrupted: true,
            stack: error?.stack,
            levelOfError: 'ROOT_LEVEL',
          })
        )
      );
    } catch (err) {
      sendErrorToRum(err);
    }
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <ErrorView error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default RootLevelErrorBoundary;
