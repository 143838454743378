import { logOutTheUser } from '../../_lib/util';
import * as types from '../actions/actionTypes';

export const preFilterMiddleware = (store: any) => (next: any) => (action: any) => {
  // if (action.type === types.USER_SET) {
  //   const { isRoleBuyer, requiresAccountSetup } = action.user;
  //   if (isRoleBuyer || requiresAccountSetup) {
  //     next(action);
  //   } else {
  //     logOutTheUser(
  //       'You have logged into wrong website. Kindly login into {website}',
  //       'error',
  //       'template'
  //     );
  //   }
  // } else {
  next(action);
  // }
};
