import React from 'react';

import { I18nContext } from '../../I18n';

import { TertiaryButton } from '../AtomComponents';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useGetQueryCacheData } from '../../hooks/useQueryCustomHooks/useGetQueryCacheData';
import apiToUrlMap from '../../ApiMapping';

export const BuyOfferAlert = (props: any) => {
  const { openOrCloseAlertDialog, stockListSettings, itemNumberWarehouse, item } = props;
  const stockAlerts = useGetQueryCacheData(apiToUrlMap.stockAlerts) as Record<
    string,
    Record<string, any>
  >;

  const I18n = React.useContext(I18nContext);
  return (
    <>
      {stockAlerts && stockAlerts[itemNumberWarehouse] ? (
        <div className="grid-x align-middle">
          <TertiaryButton
            onClick={(e) =>
              openOrCloseAlertDialog(
                true,
                {
                  alertQuantity: stockAlerts[item.itemNumberWarehouse].quantity,
                  description: stockAlerts[item.itemNumberWarehouse].description,
                  availableQuantity: item.quantityAvailable,
                  itemNumberWarehouse: item.itemNumberWarehouse,
                  incrementSize: item.incrementSize,
                },
                true
              )
            }
            startIcon={<NotificationsIcon className="inline-block vertical-middle" />}
          >
            {I18n.weWillAlertYou || 'We will alert you when we get more'}
          </TertiaryButton>
        </div>
      ) : (
        <div className="grid-x align-middle">
          <TertiaryButton
            onClick={(e) =>
              openOrCloseAlertDialog(
                false,
                {
                  alertQuantity: stockListSettings['defaultAlertQuantity'] || 100,
                  description: item.skuDescription,
                  availableQuantity: item.quantityAvailable,
                  itemNumberWarehouse: item.itemNumberWarehouse,
                  incrementSize: item.incrementSize,
                },
                true
              )
            }
            startIcon={<NotificationsNoneIcon className="inline-block vertical-middle" />}
          >
            {I18n.alertWhenMoreAvailable || 'Alert me when more are available'}
          </TertiaryButton>
        </div>
      )}
    </>
  );
};
